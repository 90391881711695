/*
*慢病管理-权限管理接口
*/
import request from '@/utils/request'

/*
  *********账号管理********
*/

//新增--角色列表
export function getRoles() {
  return request({
    url: `/admin/auth/role/roles`,
    method: 'get',
  })
}

//修改账号状态
export function adminStatus(id, status) {
  return request({
    url: `/admin/auth/admin/${id}/status`,
    method: 'put',
    data: { status }
  })
}

//管理员账号列表
export function adminList(params) {
  return request({
    url: `/admin/auth/admin`,
    method: 'get',
    params
  })
}

//渠道列表
export function agentList() {
  return request({
    url: `/admin/merchantList`,
    method: 'get',
  })
}



/*
  *********角色管理********
*/

//角色列表
export function roleList(params) {
  return request({
    url: `/admin/roles`,
    method: 'get',
    params
  })
}

export function addRoles(data) {
  return request({
    url: `/admin/roles`,
    method: 'post',
    data
  })
}

export function delRole(id) {
  return request({
    url: `/admin/roles/${id}`,
    method: 'delete',
  })
}

export function updateRoles(id,data) {
  return request({
    url: `/admin/roles/${id}`,
    method: 'put',
    data
  })
}


//角色状态修改
export function roleStatus(id, data) {
  return request({
    url: `/admin/${id}/status`,
    method: 'put',
    data
  })
}

//新增角色权限列表
export function roleAuthList(params) {
  return request({
    url: `/admin/auth/role/roleAuthList`,
    method: 'get',
    params
  })
}


/*
  *********权限管理********
*/

//权限管理列表
export function authList(params) {
  return request({
    url: '/admin/permissions',
    method: 'get',
    params
  })
}

//删除权限
export function delPermission(id) {
  return request({
    url: `/admin/permissions/${id}`,
    method: 'delete',
  })
}


//新增权限
export function addpermission(data) {
  return request({
    url: `/admin/permissions`,
    method: 'post',
    data
  })
}

//顶级权限列表
export function getTopAuth() {
  return request({
    url: `/admin/auth/getTopAuth`,
    method: 'get',
  })
}

//编辑权限
export function editPermission(id, data) {
  return request({
    url: `/admin/permissions/${id}`,
    method: 'put',
    data
  })
}



export function staffs(params) {
  return request({
    url: `/admin/getAgentStaffs`,
    method: 'get',
    params
  })
}

export function operates() {
  return request({
    url: `/admin/operates`,
    method: 'get'
  })
}

export function getTopPermissions() {
  return request({
    url: `/admin/topPermissions`,
    method: 'get'
  })
}

export function getPermissions() {
  return request({
    url: `/admin/getPermissions`,
    method: 'get'
  })
}


export function getAgents() {
  return request({
    url: `/admin/getAgents`,
    method: 'get'
  })
}

export function rolePermissionList(type) {
  return request({
    url: `/admin/rolePermissionList`,
    method: 'get',
    params:{type}
  })
}











